@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}


@media screen and (min-width: 1600px) {
  .window-tree {
    min-width: 1536px;
  }
}

@media screen and (max-width: 1200px) {
  .window-tree {
    min-width: 1200px;
  }
}

@media screen and (max-width: 1024px) {
  .window-tree {
    min-width: 1024px;
  }
}

@media screen and (max-width: 768px) {
  .window-tree {
    min-width: 768px;
  }
}

@media screen and (max-width: 640px) {
  .window-tree {
    min-width: 640px;
  }
}

.window-tree {
  overflow: scroll;
}

.sibs-block {
  position: relative;
}

.sibs-block * {
  z-index: 1;
}

.sibs-block:after {
  content: '';
  height: 250px;
  width: 1px;
  background-color: #333;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.sibs-block-parent {
  position: relative;
  height: 120px;
}

.sibs-block-parent:before {
  content: '';
  width: 50%;
  height: 1px;
  background-color: #333;
  position: absolute;
  left: 23%;
  top: 30px;
}

.sibs-block-parent:after {
  content: '';
  height: 118px;
  width: 1px;
  background-color: #333;
  position: absolute;
  left: 50%;
  bottom: -28px;
}

.sibs-block-children {
  margin-top: 40px;
  position: relative;
}

.sibs-block-children:after {
  content: '';
  height: 120px;
  width: 1px;
  background-color: #676767;
  position: absolute;
  left: 50%;
  bottom: 50%;
}

.pswp__img {
  object-fit: contain;
}

.tree-height {
  height: 800px;
}

.beta-tree-flow {
  width: 100%;;
  height: 90vh !important;
  border: solid 1px black;
  background-color: white;
}

.react-flow  {
  height: 500px !important;
  min-height: 70vh !important;
}